import Dialog from '@mui/material/Dialog';
import React from "react";
import { Typography, Paper, Box } from "@mui/material";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from "react-hook-form"
import { post } from 'aws-amplify/api';

export function CreateClubDialog(props) {
    const {register, control, handleSubmit} = useForm();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = async () => {
      setOpen(false)
    };

    const handleDelete = async () => {
        if(window.confirm('Bitte bestätigen sie die Aktion')){

        }
        handleClose();
    }


    const onSubmit = async (data) => {
        console.log(data)
        try{
        const restOperation = post({    //API CALL An Lambda
            apiName: 'smileAmplifyApi',
            path: `/club/`,
            headers: {
              'Authorization': props.accessToken  //JWT in den Header für Cognito
            },
            options: {
              body: data,
            }
          });
          
          const {body} = await restOperation.response;
          const json = await body.json(); //Umwandlung der Antwort in JSON
          console.log('POST call succeeded: ', json);}
          catch(e){
            console.log('POST call failed: ',e);
          }
        handleClose();
    }

    return(
    <React.Fragment>
        <Paper onClick={handleClickOpen}>
            <Box marginLeft={"2%"} marginRightt={"2%"}>
            Neuen Verein erstellen
            <br></br><br></br>
            Bitte Beachten: <br></br>
            Nur Administratoren können neue Vereine erstellen!
            </Box>
        </Paper>      
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} sx={{zIndex: 10001}} >
        <DialogTitle><Typography fontWeight={700} >Verein erstellen</Typography></DialogTitle>
        <DialogContent>
            Bitte hier die Daten eingeben:<br></br>
            <form onSubmit={handleSubmit(onSubmit)}>
            Name=<input required type="text" {...register("name")}/><br/>
            Webseite=<input required type="text" {...register("website")}/><br/>
            Email=<input required type="text" {...register("email")}/><br/>

            Land=<input required type="text" {...register("adress.country")}/><br/>
            Bundesland=<input required type="text" {...register("adress.state")}/><br/>
            Postleitzahl=<input required type="text" {...register("adress.postalcode")}/><br/>
            Stadt=<input required type="text" {...register("adress.city")}/><br/>
            Straße=<input required type="text" {...register("adress.street")}/><br/>
            Hausnummer=<input required type="text" {...register("adress.house_number")}/><br/>
            <br></br>
            <button type="submit">Absenden</button>
            </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
    )

}