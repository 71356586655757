import React from "react";
import { Typography, Paper,Box,TextField,MenuItem,Button, Autocomplete ,Select} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en-gb";
import { Controller,useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
import { currentSession, postNewUser } from "../components/backend";
import {useNavigate} from 'react-router-dom';
import dayjs from "dayjs";
import { PhoneInput } from 'react-international-phone';
import ListItemText from '@mui/material/ListItemText';
import { ExtraEmails } from "../components/utils/extraEmails";
import { post } from 'aws-amplify/api';

const IP = "https://gknrod8tfd.execute-api.eu-central-1.amazonaws.com/dev"


export default function AddUser({club_id,accessToken}) {
  return (
    <Box marginLeft={"2%"} marginRight={"2%"} marginTop={"0.5%"}>
      <Typography marginLeft={"1%"}> FC-Bayern - Mitglieder - Neues Mitglied </Typography>
      <Typography fontWeight={700} variant="h5"component="h5" marginBottom={"1%"} marginTop={"1%"} marginLeft={"1%"}> Neuer Spieler </Typography>
      <Box>
        <Paper>
          <br/>
          <Box marginLeft={"1%"}marginRight={"1%"}>
            <AddUserForm club_id={club_id} accessToken={accessToken}/>
          </Box>
          <br/>     
        </Paper>
        </Box>
    </Box>
  );
}





function AddUserForm({club_id,accessToken}) {
  const [emails,setEmails] = React.useState(1)
  const navigate = useNavigate();
  const options = ["Mann", "Frau"];


  const guestPlayOptions = ["Jugend","Frauen","Senioren","Allgemein",]
  const form = useForm()
  const { control, register, handleSubmit} = form
  
  const onSubmit = async (data) =>{
    if(data.phone.length < 7){
      alert("Bitte Telefonnummer überprüfen!")
      return
  }
    console.log(data);
    data.birthdate = data.birthdate.format("DD.MM.YYYY")
   
    if(data.active_since == "" || data.active_since == null){
      data.active_since = dayjs().format("DD.MM.YYYY")
    } else {
      data.active_since = data.active_since.format("DD.MM.YYYY")
    }
    data.creation_date = dayjs().format("DD.MM.YYYY");
    //await postNewUser(club_id,data,accessToken);
    try{
      const restOperation = post({    //API CALL An Lambda
          apiName: 'smileAmplifyApi',
          path: `/member/${club_id}`,
          headers: {
            'Authorization': accessToken  //JWT in den Header für Cognito
          },
          options: {
            body: data,
          }
        });
        const {body} = await restOperation.response;
        const json = await body.json(); //Umwandlung der Antwort in JSON
        console.log('POST call succeeded: ', json);}
        catch(e){
          console.log('POST call failed: ',e);
        }


    navigate("/users")
  }




  return (
    <Box component="form" sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }} autoComplete="off" onSubmit={handleSubmit(onSubmit)} >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
    
        <Box>
          <Box>
              <Typography> Allgemeine Daten </Typography>
              <Box marginTop="0.5%">
                <TextField required id="outlined-required" label="Vorname" defaultValue="" {...register("firstname")} />
                <TextField required id="outlined-required" label="Name" defaultValue="" {...register("lastname")} />
                <Controller required control={control} name="birthdate" render={({ field }) => ( <DatePicker label="Geburtstag" placeholderText="Select date" onChange={(date) => field.onChange(date)} selected={field.value} /> )} />

              <div style={{ display: "inline-flex" }}>
                <Autocomplete disablePortal required id="gender" options={options} sx={{ width: 300 }} renderInput={(params) => ( <TextField {...params} label="Geschlecht" required {...register("gender")}/> )} />
              </div>
              </Box>
          </Box>
      
          <Box> 
            <Typography> Kontaktdaten </Typography>
            <Box display="flex">
              <TextField required id="outlined-required" label="Email" defaultValue="" type="email" {...register("email")} />
              <Controller control={control} name="phone" rules={{ required: true }} render={({field}) => <PhoneInput defaultCountry='de' style={{"--react-international-phone-height":"56px",zIndex:2,margin:"8px"}} {...field}/>} required/>
             </Box>
          
            <Typography> Adressdaten </Typography>
            <Box display="flex">
              <TextField required id="outlined-required" label="Land" defaultValue="Deutschland" {...register("adress.country")}/>
              <TextField required id="outlined-required" label="Bundesland" defaultValue="" {...register("adress.state")}/>
              <TextField required id="outlined-required" label="Postleitzahl" defaultValue="" {...register("adress.postalcode")}/>
              <TextField required id="outlined-required" label="Stadt" defaultValue="" {...register("adress.city")}/>      
              <TextField required id="outlined-required" label="Straße" defaultValue="" {...register("adress.street")}/>
              <TextField required id="outlined-required" label="Hausnummer" defaultValue="" {...register("adress.house_number")}/>
            </Box>  
          </Box>

          <Box >
            <Typography> Spielereigenschaften </Typography>
            <Box marginTop="0.5%" display="flex" justifyContent={"left"}>
              <TextField required={true} id="outlined-required" select label="Mitgliedschaft" defaultValue="active" {...register("membership")}> 
                <MenuItem value="active">aktiv</MenuItem>
                <MenuItem value="passive">passiv</MenuItem></TextField>
              <TextField id="outlined-required" label="FideID" {...register("fide_id")} />
              
              <Autocomplete disablePortal id="guestplay" options={guestPlayOptions} sx={{ width: "300px" }} renderInput={(params) => ( <TextField {...params} label="Gastspielberechtigung" {...register("guestplay")}/> )} />
              </Box>
              <Controller control={control} name="active_since" render={({ field }) => ( <DatePicker label="Aktiv seit" placeholderText="Select date" onChange={(date) => field.onChange(date)} selected={field.value} /> )} />
          </Box>
          <Box  > 
            <ExtraEmails emails={emails} register={register} ></ExtraEmails>
            <Button style={{maxWidth: '150px', maxHeight: '30px', minWidth: '130px', minHeight: '30px', marginLeft:"9px",marginTop:"1%"}} variant="contained" onClick={() => {if(emails<4){setEmails(emails + 1)}}} marginLeft={"1%"} >Mehr Emails</Button>
          </Box>  
        </Box>
        <br></br>
        <Box>
          <Link to="../users"><Button variant="contained" color="secondary">Zurück</Button></Link>
          <Button variant="contained" color="success" sx={{marginLeft:"1%"}} type="submit"> Hinzufügen</Button>
        </Box>
      </LocalizationProvider>
    </Box>
  );
}