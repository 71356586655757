import { Box, IconButton } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteUser} from "./backend";
import {del} from 'aws-amplify/api';

export default function ConfirmDeleteDialog({accessToken,club,member,updatePlayers}) {
  const [open, setOpen] = React.useState(false); //State für das Öffen und Schließen des Dialogs

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const  handleDelete = async() => {
    console.log("Deleting:" + member);
    try {
      const restOperation = del({    //API CALL AN Lambda
        apiName: 'smileAmplifyApi',
        path: `/member/${club}?memberId=${member}`,
        headers: {
          'Authorization': accessToken  //JWT in den Header für Cognito
        }
      });
      const {body} = await restOperation.response;
      const json = await body.json(); //Umwandlung der Antwort in JSON
      console.log('DELETE call succeeded: ', json);
    } catch (e) {
      console.log('DELETE call failed: ', e); //Bei Fehler error Zurückgeben
    }
    await updatePlayers() //vor dem Schließen die Liste aktualisieren
    setOpen(false); // Dialog Fenster schließen
  }

  return (
    <React.Fragment>
      <IconButton color="error" onClick={handleClickOpen}>
      <DeleteIcon/>
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{zIndex: 10001}}
      >
        <DialogTitle id="alert-dialog-title">
          {"Wollen sie den Benutzer wirklich löschen?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Der ausgewählte Benutzer wird somit aus der Datenbank entfernt.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{color:"black"}}>Abbrechen</Button>
          <Button onClick={handleDelete} sx={{color:"black"}} autoFocus>
            Fortfahren
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}





//saving just in Case
//{ field: "actions", headerName: "Bearbeiten", width:150 , renderCell: (params) => {return (<Box><ConfirmDeleteDialog club={club} id={params.row.id} getNewPlayers={getNewPlayers}/><Button variant="contained"><EditIcon/></Button><Button variant="contained" onClick={() => {console.log("Deleting:" + params.row.id);deleteUser(club,params.row.id);updatePlayers(club,getNewPlayers)}}> <DeleteIcon/> </Button></Box>)},}]