import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import AddUser from '../../pages/addUser';
import { Controller,useForm } from 'react-hook-form';
import { Typography, Paper, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en-gb";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import { currentSession, postNewOffical, postNewUser, putUpdatedUser} from "../backend";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { MuiPhone } from '../phoneInput';
import {AdressDataForm} from '../utils/adressData.jsx';

export default function AddOfficalsDialog({open,handleClose,club_id,accessToken}) {
    return (
      <React.Fragment>
        <Dialog open={open}onClose={handleClose}fullWidth={true}maxWidth={"lg"}sx={{zIndex:10001}}>
          <DialogTitle><Typography fontWeight={700} >Funktionär hinzufügen</Typography></DialogTitle>
          <DialogContent>

            <AddOfficialsForm></AddOfficialsForm>
            
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );


    function AddOfficialsForm(){

        const [emails,setEmails] = React.useState(1)
        const {register, control, handleSubmit} = useForm();
        const onSubmit = async (data) => {
            if(data.phone.length < 7){
                alert("Bitte Telefonnummer überprüfen!")
                return
            }
            data.club_id = club_id;
            console.log("Form Data:");console.log(data)
            await postNewOffical(club_id,data,accessToken)
            handleClose();
        }

        return(
            <Box>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}>
                <Paper>
                <Box margin={"1%"} padding={"2%"}> 
                    <Typography>Personaldaten</Typography>
                    <br></br>
                    <Box display="flex">
                    <TextField variant="outlined" label="Vorname" {...register("firstname")} required>Hello</TextField>
                    <TextField variant="outlined" label="Nachname" {...register("lastname")} required></TextField>
                    <TextField variant="outlined" label="Email-Adresse" type="email" {...register("email")} required></TextField>
                    <TextField variant="outlined" label="Aufgabengebiet" {...register("responsibility")} required></TextField>
                    <Controller control={control} name="phone" rules={{ required: true }} render={({field}) => <PhoneInput defaultCountry='de' style={{"--react-international-phone-height":"56px",zIndex:2,margin:"8px"}} {...field}/>} required/>
                    </Box>
                </Box>
                </Paper>
                <Paper>
                    <Box margin={"1%"} padding={"2%"}>
                        <Typography>Adressdaten</Typography>
                        <br></br>
                        <AdressDataForm register={register}/>          
                    </Box>
                </Paper>
                    <Box marginTop={"1%"}>
                    <br></br>
                    <Button variant="contained" onClick={() => {if(emails<4){setEmails(emails + 1)}}} >Mehr Email-Adressen Hinzufügen</Button>
                    <br></br>
                    <ExtraEmails emails={emails} register={register}></ExtraEmails>
                    </Box>
                    <Box justifyContent={"left"} display={"flex"} padding={"0px"} marginTop={"3%"}>
                            <Button variant="contained" color="error" onClick={handleClose} >Abbrechen</Button>
                            <Button variant="contained" type="submit" color="success" sx={{marginLeft:"1%"}}>Speichern</Button>
                    </Box>
                </Box>
            </Box>
        )

    }

    function ExtraEmails({emails,register}){
        
        switch(emails){
            case(2):
                return(
                <Box marginTop={"1%"}>
                    <TextField variant="outlined" label="Email-Adresse" type="email" {...register("emails.email2")}></TextField>
                </Box>
                )
            case(3):
                return(
                <Box marginTop={"1%"}>
                    <TextField variant="outlined" label="Email-Adresse" type="email" {...register("emails.email2")}></TextField>
                    <TextField variant="outlined" label="Email-Adresse" type="email" {...register("emails.email3")}></TextField>
                </Box>)
            case(4):
            return(
            <Box marginTop={"1%"}>
                <TextField variant="outlined" label="Email-Adresse" type="email" {...register("emails.email2")}></TextField>
                <TextField variant="outlined" label="Email-Adresse" type="email" {...register("emails.email3")}></TextField>
                <TextField variant="outlined" label="Email-Adresse" type="email" {...register("emails.email4")}></TextField>
            </Box>)
        }
    }

}