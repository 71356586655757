import { Box } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteOfficial, deleteUser} from "../backend";

export default function DeleteOfficialsDialog({club_id,official_id,accessToken}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
  };
  
  const  handleDelete = async() => {
    console.log("Deleting Official:");
    let res = await deleteOfficial(club_id,official_id,accessToken)
    console.log(res)
    window.location.reload()
  }

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} sx={{color:"black",backgroundColor:"white"}}>
      <DeleteIcon/>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Wollen sie den Vertreter wirklich löschen?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Der ausgewählte Vertreter wird somit aus der Datenbank entfernt.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{color:"black"}}>Abbrechen</Button>
          <Button onClick={handleDelete} sx={{color:"black"}} autoFocus>
            Fortfahren
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )  
}