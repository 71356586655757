import Dialog from '@mui/material/Dialog';
import React from "react";
import { Typography, Paper, Box } from "@mui/material";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from "react-hook-form"
import { del } from 'aws-amplify/api';

export function DeleteClubDialog(props) {
  const {register, control, handleSubmit} = useForm();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = async () => {
      setOpen(false)
    };

    const handleDelete = async () => {
        if(window.confirm('Bitte bestätigen sie die Aktion')){

        }
        handleClose();
    }
    const onSubmit = async (data) => {
      console.log(data)
      try{
      const restOperation = del({    //API CALL An Lambda
          apiName: 'smileAmplifyApi',
          path: `/club/${data.id}`,
          headers: {
            'Authorization': props.accessToken  //JWT in den Header für Cognito
          },
        });
        
        const {body} = await restOperation.response;
        const json = await body.json(); //Umwandlung der Antwort in JSON
        console.log('DELETE call succeeded: ', json);}
        catch(e){
          console.log('DELETE call failed: ',e);
        }
      handleClose();
  }

    return(
    <React.Fragment>
        <Paper onClick={handleClickOpen}>
            <Box marginLeft={"2%"} marginRightt={"2%"}>
            Verein löschen
            <br></br><br></br>
            Bitte Beachten: <br></br>
            Nur Administratoren können Vereine löschen!
            </Box>
        </Paper>      
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} sx={{zIndex: 10001}} >
        <DialogTitle><Typography fontWeight={700} >Verein Löschen</Typography></DialogTitle>
        <DialogContent>
            Sind sie sich absolut sicher das sie den Verein mit allen Mitgliedern, Funktionären, Gremien usw. löschen wollen? <br/>
            <form onSubmit={handleSubmit(onSubmit)}>
            VKZ=<input required type="text" {...register("id")}/><br/>
            <br></br>
            <button type="submit">Absenden</button>
            </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
    )


}