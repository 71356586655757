import {Box, TextField} from "@mui/material"
import * as React from 'react';

export function AdressDataForm({register}){
return(
<Box display="flex">
              <TextField required id="outlined-required" label="Land" defaultValue="Deutschland" {...register("adress.country")}/>
              <TextField required id="outlined-required" label="Bundesland" defaultValue="" {...register("adress.state")}/>
              <TextField required id="outlined-required" label="Postleitzahl" defaultValue="" {...register("adress.postalcode")}/>
              <TextField required id="outlined-required" label="Stadt" defaultValue="" {...register("adress.city")}/>      
              <TextField required id="outlined-required" label="Straße" defaultValue="" {...register("adress.street")}/>
              <TextField required id="outlined-required" label="Hausnummer" defaultValue="" {...register("adress.house_number")}/>
</Box>  
)
}