import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { NavHeader } from "../components/utils/navHeader";
import { Box, Typography,Paper,Button, responsiveFontSizes } from "@mui/material";
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import AddOfficalsDialog from "../components/officials/addOfficialsDialog";
import { EditOfficalsDialog } from "../components/officials/editOfficialsDialog";
import DeleteOfficialsDialog from "../components/officials/deleteOfficialsDialog";

const IP = "https://gknrod8tfd.execute-api.eu-central-1.amazonaws.com/dev"

export default function Committee({club_id,accessToken}){
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = async () => {
      await updateData();
      setOpen(false)
    };

    // Funktion zum Abrufen der Daten
  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(IP + "/officals/" + club_id,{
        headers: {
            'Authorization': accessToken,
        }});
      console.log(response)
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
    }, [club_id]);

  // Initiales Laden der Daten
    useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Funktion zum Aktualisieren der Daten
  const updateData = async () => {
    await fetchData();
    console.log("updating!")
  };



    return(
        <Box marginLeft={"2%"} marginRight={"2%"} marginTop={"0.5%"}>
            <NavHeader first={"FC-Bayern"} second={"Verein"} third = {"Gremien"}></NavHeader>
            <Typography fontWeight={700} variant="h5"component="h5" marginBottom={"1%"} marginTop={"1%"} marginLeft={"1%"}>Gremien</Typography>
          <Box>
        <Paper>
        <br/>
          <Box marginLeft={"1%"}marginRight={"1%"}>
            <Box>
              <OfficialDataTable />
              <br></br>
              <Button variant="contained" color="info" onClick={updateData}>Aktualisieren</Button>
            </Box>
            <Box marginTop="1%">
                  <Button variant="contained" color="success" onClick={handleClickOpen}>Gremium Hinzufügen</Button><AddOfficalsDialog open={open} handleClose={handleClose} club_id={club_id} accessToken={accessToken}></AddOfficalsDialog>
            </Box>
          </Box>
          <br></br>
        </Paper>
      </Box>
        </Box>
    )
    
    function OfficialDataTable(){
        const rows = [{
            "id":1,
            "name": "Schiedsrichterkommission",
            },{
            "id":2,
            "name": "Wertungskommision"
            }
        ]
    const colums= [
        {field : "name" ,headerName: "Name", width: 300},
        { field: "actions", headerName: "Bearbeiten", width:150 , renderCell: (params) => {return (<Box><EditOfficalsDialog accessToken={accessToken} club_id={club_id} player={params.row}/><DeleteOfficialsDialog accessToken={accessToken} club_id={club_id} official_id={params.row.id}/></Box>)}}]
        return(
            <DataGrid rows={rows} columns={colums} loading={loading} error={error}  slots={{toolbar: GridToolbar}}/>)
    }
}