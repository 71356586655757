import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route } from "react-router-dom";
import { useTheme, Box, Typography, IconButton, Button } from "@mui/material";
import { TopMenu } from "./top_menu";
import SmileSidebar from "./smile_sidebar";
import Dashboard from "./dashboard.jsx"
import { withAuthenticator } from '@aws-amplify/ui-react';
import ClubData from './pages/clubData.jsx';
import AddUser from "./pages/addUser.js"
import "./App.css";
import { useEffect, useState, createContext } from "react";
import MembershipFees from './pages/membership_fees.jsx';
import { fetchAuthSession } from 'aws-amplify/auth';
import Officials from './pages/officials.jsx';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { defaultStorage } from 'aws-amplify/utils'
import Administration from './pages/administration.jsx';
import History from "./pages/history.jsx"
import Settings from "./pages/settings.jsx"
import Members from './pages/members.jsx';
import {theme, theme2} from './theme.jsx';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Committee from './pages/committee.jsx';
import Communication from './pages/communication.jsx';
import Testing from './pages/testing.jsx';

function App({ signOut, user }) {

  const [accessToken, setAccessToken] = useState("")
  const [club_id, setClub_id] = useState("")
  const [darkTheme, setDarkTheme] = useState(false)
  const [currTheme, setCurrTheme] = useState(theme)

  const changeTheme = () => {
    let bool = !darkTheme
    bool ? setDarkTheme(true) : setDarkTheme(false)
    bool ? setCurrTheme(theme2) : setCurrTheme(theme)
  }

  const changeClub_id = (vkz) => {
    setClub_id(vkz)
  }

  useEffect(() => {
    const getUsertoken = async () => {
      try{
      const tokens = await fetchAuthSession();
      setAccessToken(tokens.tokens.accessToken)
      console.log("Tokens:");console.log(tokens)
      console.log("The User belongs to Club:");console.log(tokens.tokens.idToken.payload.profile)
      setClub_id(tokens.tokens.idToken.payload.profile)
      cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);
    } catch (error){console.log(error)}
  }
  getUsertoken();
  },[])

  useEffect(() => { if(darkTheme){document.body.style.backgroundColor = '#121212'}else{document.body.style.backgroundColor = '#f7f8fa'} }, [currTheme])

    return (
      <ThemeProvider theme={currTheme}>
      <CssBaseline />
      <>
        <Box sx={{marginLeft:"240px"}}>
          <TopMenu signOut={signOut} user={user} darkMode={darkTheme} themeChange={() => changeTheme} club_id={club_id} changeClub_id={changeClub_id}></TopMenu>
          <Button onClick={() => changeClub_id(2)}></Button>
        </Box>
        <Box sx={{position: "fixed",display: "flex",height: "100vh",top: 0,bottom: 0,zIndex: 1000,width: "240px",}}>
          <SmileSidebar darkMode={darkTheme}></SmileSidebar>
        </Box>
        <Box sx={{marginLeft:"250px"}}>
          <Routes>
              <Route path="/" element={<Dashboard club_id={club_id} accessToken={accessToken}/>} ></Route>
              <Route path="administration" element={<Administration club_id={club_id} accessToken={accessToken}/>} ></Route>
              <Route path="/fees" element={<MembershipFees/>} accessToken={accessToken}></Route>
              <Route path="/clubs" element={<ClubData club_id={club_id} accessToken={accessToken}></ClubData>}></Route>
              <Route path="/officials" element={<Officials club_id={club_id} accessToken={accessToken}></Officials>}></Route>
              <Route path="/committes" element={<Committee club_id={club_id} accessToken={accessToken}></Committee>}></Route>
              <Route path="/communication" element={<Communication> club_id={club_id} accessToken={accessToken}</Communication>}></Route>
              <Route path="/settings" element={<Settings accessToken={accessToken}/>}></Route>
              <Route path="/history" element={<History club_id={club_id} accessToken={accessToken}></History>}></Route>
              <Route path="/add_user" element={<AddUser club_id={club_id} accessToken={accessToken}/>}></Route>
              <Route path="/users" element={<Members club_id={club_id} accessToken={accessToken}/>}></Route>
              <Route path="/testing" element={<Testing/>}></Route>
          </Routes>
        </Box>
      </>
      </ThemeProvider>
    );
  }


  export default withAuthenticator(App);