import { createTheme } from '@mui/material/styles';
import { deDE } from '@mui/x-data-grid/locales';
import { DataGrid } from '@mui/x-data-grid';




export const theme = createTheme({
    palette: {
      primary: {
        //main: '#1976d2'<_blau ,#ffffff
        main: '#1976d2', // Primärfarbe
      },
      secondary: {
        //main: '#dc004e', // Sekundärfarbe
        main: '#dc004e', // Sekundärfarbe
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    
  },
  deDE,);
  

 export const theme2 = createTheme({
   palette: {
     mode: 'dark',
   },
   typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
},deDE);