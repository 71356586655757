import { Box, Typography, Paper, Button, Dialog, CircularProgress,List, ListItem, Stack } from "@mui/material";
import React, { useState, useEffect, useCallback } from 'react';
import { NavHeader } from "../components/utils/navHeader";
import axios from "axios";
import { list } from "aws-amplify/storage";

export default function HistoryLog({club_id,accessToken}){

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const IP = "https://gknrod8tfd.execute-api.eu-central-1.amazonaws.com/dev"

    // Funktion zum Abrufen der Daten
  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(IP + "/history/" + club_id,{
        headers: {
            'Authorization': accessToken,
        }});
      console.log(response)
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
    }, [club_id]);

  // Initiales Laden der Daten
    useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Funktion zum Aktualisieren der Daten
  const updateData = async () => {
    await fetchData();
    console.log("updating!")
  };

    if(loading){
        return <CircularProgress></CircularProgress>
    }

    return(
    <Box marginLeft={"2%"} marginRight={"2%"} marginTop={"0.5%"}>
        <NavHeader first="Verein" second="Historie" third="Ereignissprotokoll"/>
        <Typography fontWeight={700} variant="h5"component="h5" marginBottom={"1%"} marginTop={"1%"} marginLeft={"1%"} > Historie </Typography>
      <Box>
    {data.map((entity) => {return(<Paper sx={{marginBottom:"0.5%"}}><List orientation="horizontal" size="sm" style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>{Object.entries(entity).map((value)=>{console.log(value);return(<ListItem>{value[0]}<br/>{value[1]}</ListItem>)})}</List></Paper>)})}
    </Box>
    </Box> 
    )
}



