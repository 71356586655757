import React from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState,useCallback  } from "react";
import { getPlayersFromClub, currentSession, deleteUser, getPlayersByClub } from "../components/backend";
import { fetchAuthSession } from "aws-amplify/auth";
import { get } from 'aws-amplify/api';
import { Link, redirect } from "react-router-dom";
import { NavHeader } from "../components/utils/navHeader";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDeleteDialog from "../components/confirmDeleteDialog";
import EditDialog from "../components/editDialog";
import AddUser from "./addUser";
import { useMemo } from 'react';
import { AccountCircle, Send } from '@mui/icons-material';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import dayjs from 'dayjs';
//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//MRT Imports
import { MaterialReactTable, useMaterialReactTable, MRT_GlobalFilterTextField, MRT_ToggleFiltersButton, } from 'material-react-table';

//Material UI Imports
import { Box, Button, ListItemIcon, MenuItem, Typography, lighten, Paper, } from '@mui/material';
//Icons Imports

import { IconButton } from '@mui/material';
import {
  Email as EmailIcon,
} from '@mui/icons-material';
//Mock Data

const Example = ({club_id,accessToken}) => {
  const [data, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(()=> { //UseEffekt holt einmal bei erstellen der Komponente und bei Änderung der Abhängikeiten
    async function getMembers() {
      try {
        if(club_id == null || club_id == ""){return} //Falls die VereinsID noch nicht bereit ist, nichts unternehmen
        const restOperation = get({    //API CALL AN Lambda
          apiName: 'smileAmplifyApi',
          path: `/members/${club_id}`,
          headers: {
            'Authorization': accessToken  //JWT in den Header für Cognito
          }
        });
        const {body} = await restOperation.response;
        const json = await body.json(); //Umwandlung der Antwort in JSON
        setPlayers(json)
        console.log('GET call succeeded: ', json);
        return json
      } catch (e) {
        console.log('GET call failed: ', e); //Bei Fehler error Zurückgeben
      }
    }
      getMembers();
  },[club_id]) //Bei Änderung des Vereins muss neu Abgefragt werden

  const updatePlayers = async () =>{
    async function getMembers() {
      try {
        if(club_id == null || club_id == ""){return} //Falls die VereinsID noch nicht bereit ist, nichts unternehmen
        const restOperation = get({    //API CALL AN Lambda
          apiName: 'smileAmplifyApi',
          path: `/members/${club_id}`,
          headers: {
            'Authorization': accessToken  //JWT in den Header für Cognito
          }
        });
        const {body} = await restOperation.response;
        const json = await body.json(); //Umwandlung der Antwort in JSON
        setPlayers(json)
        console.log('GET call succeeded: ', json);
        return json
      } catch (e) {
        console.log('GET call failed: ', e); //Bei Fehler error Zurückgeben
      }
    }
      getMembers();
  }

  const columns = useMemo(
    () =>[
          { accessorFn: (row) => `${row.firstname} ${row.lastname}`, id: 'name', header: 'Name', size: 170, },
          { accessorKey: 'firstname', header: "Vorname", size: 150,
            filterVariant: 'autocomplete', },
          { accessorKey: 'lastname', header: "Nachname", size: 150,
            filterVariant: 'autocomplete', },
          { accessorFn: (row) => {if(row.membership == "active"){return("Aktiv")}else{return("Passiv")}}, header: "Mitgliedschaft", size: 140,filterVariant: 'autocomplete' },
          { accessorKey: 'birthdate', header: "Geburtstag", size: 150, type:"date", sortingFns:(rowA, rowB, columnId) => {return dayjs(rowA.birthdate,"DD.MM.YYYY").diff(dayjs(rowB.birthdate,"DD.MM.YYYY"))}},

          { accessorKey: 'guestplay', header: "Gastspielberechtigung", size: 150, },
          { accessorKey: "gender", header: "Geschlecht",filterVariant: 'select', size: 130, },
          { accessorKey: 'email', enableClickToCopy: true, filterVariant: 'autocomplete', header: 'Email', size: 200, },
          { accessorFn: (row) => {if(row.membership == "active"){return row.active_since}}, header: "Aktiv seit", size: 150 },
          { accessorFn: (row) => {if(row.membership == "active"){return dayjs(row.active_since, "DD.MM.YYYY").add(6,"month").format("DD.MM.YYYY")}}, header: 'Aktiv bis', size: 170, },
          { accessorKey: 'creation_date', header: "Erstellt am", size: 150 },
      ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    localization:MRT_Localization_DE,
    enableColumnFilterModes: "true",
    enableColumnOrdering: false,
    enableFacetedValues: true,
    enableColumnResizing: true,
    enableColumnActions:false, //die drei Punkte da
    enableRowActions:true,
    positionActionsColumn: 'last',
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      columnVisibility: { name: false,email:false },
      density:"compact"
    },
    displayColumnDefOptions:{
      'mrt-row-actions': {
        size: 180, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
        grow: false,
      },
    },
    paginationDisplayMode: 'pages',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
     positionGlobalFilter:"left",
     layoutMode:"grid-no-grow",

     renderRowActions:({ row, table }) => (
       <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
         <IconButton
          color="primary"
          onClick={() =>
           window.open(
              `mailto:${row.original.email}?subject=Hallo ${row.original.firstname}!`,
            )
          }
        >
           <EmailIcon />
        </IconButton>
<EditDialog accessToken={accessToken} club_id={club_id} player={row.original} updatePlayers={updatePlayers}/>
<ConfirmDeleteDialog accessToken={accessToken} club={club_id} member={row.original.id} updatePlayers={updatePlayers}/>
</Box>


)
  });

  return (
  <React.Fragment>
     <MaterialReactTable table={table} /> 
      <br></br>
      <Button variant="contained" color="info" onClick={updatePlayers}>Aktualisieren</Button>
  </React.Fragment>
  );
};

const Members = ({club_id, accessToken}) => (
  //App.tsx or AppProviders file
  <LocalizationProvider dateAdapter={AdapterDayjs}>
<Box marginLeft={"2%"} marginRight={"2%"} marginTop={"0.5%"}>
      <NavHeader first="FC-Bayern" second="Mitglieder" third="Mitgliedertabelle"/>
      <Typography fontWeight={700} variant="h5"component="h5" marginBottom={"1%"} marginTop={"1%"} marginLeft={"1%"}>Mitgliederverwaltung</Typography>
      <Box>
        <Paper>
        <br/>
          <Box marginLeft={"1%"}marginRight={"1%"}>
            <Box>
            <Example club_id={club_id} accessToken={accessToken} />
              <br></br>
            </Box>
            <Box marginTop="1%">
                <Link to="../add_user">
                  <Button variant="contained" color="success">Benutzer Hinzufügen</Button>
                </Link>
            </Box>
          </Box>
          <br></br>
        </Paper>
      </Box>
    </Box>
  </LocalizationProvider>
);

export default Members;