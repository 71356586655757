import Dialog from '@mui/material/Dialog';
import React from "react";
import { useState,useEffect } from 'react';
import { getClubData, putUpdatedClub } from '../backend';
import { Typography, Paper, Box, CircularProgress } from "@mui/material";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { put } from 'aws-amplify/api';

export function ChangeVKZDialog({club_id,vkz,accessToken}) {

    const [open, setOpen] = React.useState(false);
    const [newVkz, setNewVkz] = useState(null);

    const handleClose = async () => {
        setOpen(false)
      };
  
    const handleSubmit = async (data) => {
        console.log(console.log("Changing VKZ from",vkz,"to",newVkz))
        if(newVkz.length != 5 || newVkz == null ){alert("VKZ muss für Vereine 5 Zahlen lang sein!");handleClose();}
        try{
          const restOperation = put({    //API CALL An Lambda
              apiName: 'smileAmplifyApi',
              path: `/club/${data.id}`,
              headers: {
                //'Authorization': props.accessToken  //JWT in den Header für Cognito
              },
              options: {
                body: data,
              }
            });            
            const {body} = await restOperation.response;
            const json = await body.json(); //Umwandlung der Antwort in JSON
            console.log('PUT call succeeded: ', json);}
            catch(e){
              console.log('PUT call failed: ',e);
            }
        //let res = await putUpdatedClub(club_id,{club_id:club_id,vkz:newVkz},accessToken);
        //console.log(res)
        window.location.reload();
    }
    console.log(open)

    return(
    <React.Fragment>
        <Paper onClick={() => setOpen(true)}>
            <Box marginLeft={"2%"} marginRight={"2%"}>
            Verein VKZ ändern
            <br></br><br></br>
            Bitte Beachten: <br></br>
            Nur Administratoren können die VKZ ändern!
            </Box>   
            </Paper> 
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} sx={{zIndex: 10001}} >
              <DialogTitle><Typography fontWeight={700} >VKZ ändern</Typography></DialogTitle>
              <DialogContent>
                <Paper>
                    <Box padding={"10px"}>
                    Alte VKZ =  {vkz ? vkz : "loading.."}
                    </Box>
                </Paper>
                <Paper >
                <Box padding={"10px"}>
                    Neue VKZ = <input type="text" name="vkzID" onChange={e => setNewVkz(e.target.value)}></input>
                    </Box>
                </Paper>
                <br></br>
                Sind sie sich sicher das sie die VKZ ändern wollen? <br/>
              <Button color="error" variant="contained" onClick={handleSubmit} sx={{marginTop:"1%"}}> Ändern </Button>
              <Button color="error" variant="contained" onClick={handleClose} sx={{marginTop:"1%"}}> Zurück </Button>
            </DialogContent>
          </Dialog>          
    </React.Fragment>
    )

    


}