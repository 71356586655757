import { Drawer, Typography } from "@mui/material";
import { Sidebar, Menu, MenuItem, SubMenu, sidebarClasses } from "react-pro-sidebar";
import {Link} from "react-router-dom"
import EmailIcon from '@mui/icons-material/Email';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import EventIcon from '@mui/icons-material/Event';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HistoryIcon from '@mui/icons-material/History';
import { useTheme } from '@mui/material/styles';

export default function SmileSidebar({darkMode}) {
  const theme = useTheme();

  const drawerWidth = 240;
  var color = "#121212";
  darkMode ? color="#121212" : color="#2196f3"

  return (
    //backgroundColor="#2196f3"
    <Sidebar backgroundColor={color}>
      <br></br>
      <Typography variant="h4" align="center"> SMILE </Typography>
      <br></br>
      <Menu>
        <MenuItem component={<Link to="/"/>} icon={<DashboardIcon />}>Mein Portal</MenuItem>
        <SubMenu label="Mitglieder" icon={<GroupIcon />}>
          <MenuItem component={<Link to="/users"/>}> Mitgliedertabelle </MenuItem>
        </SubMenu>
        <SubMenu label="Verein" icon={<GroupsIcon/>}>
          <MenuItem component={<Link to="/clubs"/>}> Vereinsdaten</MenuItem>
          <MenuItem component={<Link to="officials"/>}> Funktionäre </MenuItem>
          <MenuItem component={<Link to="/committes"/>}> Gremien </MenuItem>
        </SubMenu>
        <MenuItem  component={<Link to="/fees"/>} icon={<AttachMoneyIcon />} > Beiträge </MenuItem>
        <MenuItem component={<Link to="/communication"/>} icon={<EmailIcon />}> Kommunikation </MenuItem>
        <MenuItem component={<Link to="/history"/>} icon={<HistoryIcon />}> Historie </MenuItem> 
        <MenuItem component={<Link to="/administration"/>} icon={<AdminPanelSettingsIcon/>}> Administration </MenuItem>
        <MenuItem component={<Link to="/settings"/>} icon={<SettingsIcon/>}> Einstellungen </MenuItem>
      </Menu>
    </Sidebar>
  );
}
