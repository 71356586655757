import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App.jsx"
import { BrowserRouter } from 'react-router-dom';


import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
Amplify.configure(config);


const IP = "https://gknrod8tfd.execute-api.eu-central-1.amazonaws.com/dev"
//const IP = "http://localhost:8000/mitglieder/"

//Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(   
        <BrowserRouter>
            <App/>
        </BrowserRouter>
);

//<React.StrictMode></React.StrictMode>