import { Box, Typography, Paper, Button } from "@mui/material";
import { Link, redirect } from "react-router-dom";

export function NavHeader(props){

    return(
      <Box marginLeft={"1%"} display="flex" flexGrow={1}>
        <Link to="/" style={{ textDecoration: 'none',color: "inherit", marginRight:"0.5%"}}><Typography> {props.first} </Typography></Link>
        <Typography style={{marginRight:"0.5%"}}> - </Typography>
        <Link to="/users" style={{ textDecoration: 'none',color: "inherit",marginRight:"0.5%"}}><Typography> {props.second} </Typography></Link>
        <Typography style={{marginRight:"0.5%"}}> - </Typography>
        <Link to="/users" style={{ textDecoration: 'none',color: "inherit",marginRight:"0.5%"}}><Typography> {props.third} </Typography></Link>
      </Box>
    )

}