import { Container, Divider, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { NavHeader } from "../components/utils/navHeader";

export default function MembershipFees() {
  return (
    <Box marginLeft={"2%"} marginRight={"2%"} marginTop={"0.5%"}>
      <NavHeader first="FC-Bayern" second="Beträge" third="Beitragsdaten"/>
      <Typography fontWeight={700} variant="h5"component="h5" marginBottom={"1%"} marginTop={"1%"} marginLeft={"1%"} > Beiträge </Typography>
      <Box marginLeft={"1%"} marginTop={"1%"} sx={{maxWidth:"700px"}}>
      <Paper marginRight={"2%"}>
        <Box marginLeft={"2%"} >
      <Typography variant="h6" > Hier können sie ihre Beiträge anpassen und erheben!</Typography>
      </Box>
      </Paper>
      <br></br>
      <Paper>
      <Box marginRight={"2%"} marginLeft={"2%"}>
        <Typography variant="h6" >Aktuelle Beiträge:</Typography>
      <Stack>
          <Divider></Divider>
          <Typography variant="h6" >Aktive Mitglieder:</Typography>
          <Typography variant="h6" >50€</Typography>
          <Divider></Divider>
          <Typography variant="h6" >Passive Mitglieder:</Typography>
          <Typography variant="h6" >40€</Typography>
          <Divider></Divider>
          <Typography variant="h6" >Senioren:</Typography>
          <Typography variant="h6" >30€</Typography>
          <Divider></Divider>
          <Typography variant="h6" >Jugend:</Typography>
          <Typography variant="h6" >30€</Typography>
          <Divider></Divider>          
      </Stack>
      </Box>
      </Paper>
      <Paper>
      <Box margin={"2%"}>
        <Stack>   
          <Typography variant="h6" >Neue Beitragszahlung anlegen:</Typography>
          <Typography>Empfänger:</Typography>
          <div sx={{width:"400px"}}><input name="reciever"></input></div>
          <Typography>Beitragstyp:</Typography>
          <div sx={{width:"400px"}}><select name="type"><option>Aktiv</option><option>Passiv</option><option>Senioren</option><option>Jugend</option></select></div>
          <Typography>IBAN:</Typography>
          <div sx={{width:"400px"}}><input name="iban"></input></div>
          <br></br>
          <div sx={{width:"400px"}}><input type="submit"></input></div>
        </Stack>  
      </Box>
      <br></br>
      </Paper>
        </Box>
    </Box>
  );
}
