import { Typography, TextField, Box, Grid, Paper, Buttom, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from '@mui/icons-material/Save';
import { NavHeader } from "../components/utils/navHeader";
import { useEffect, useState,useCallback  } from "react";
import { getClubData, putUpdatedClub } from "../components/backend";
import { get } from 'aws-amplify/api';


export default function ClubData({club_id,accessToken}) {

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(()=> { //UseEffekt holt einmal bei erstellen der Komponente und bei Änderung der Abhängikeiten
    setLoading(true);
    setError(null);
    async function getClubData() {
      try {
        if(club_id == null || club_id == ""){return} //Falls die VereinsID noch nicht bereit ist, nichts unternehmen
        const restOperation = get({    //API CALL AN Lambda
          apiName: 'smileAmplifyApi',
          path: `/club/${club_id}`,
          headers: {
            'Authorization': accessToken  //JWT in den Header für Cognito
          }
        });
        const {body} = await restOperation.response;
        const json = await body.json(); //Umwandlung der Antwort in JSON
        setData(json)
        console.log('GET call succeeded: ', json);
        setLoading(false);
        return json
      } catch (e) {
        setError(true);
        console.log('GET call failed: ', JSON.parse(e.response.body)); //Bei Fehler error Zurückgeben
      }
    }
      getClubData();
  },[club_id]) //Bei Änderung des Vereins muss neu Abgefragt werden

  if(data == null){
    return <Box sx={{ display: 'flex', justifyContent:"center"}}> <CircularProgress /> </Box>
  }

  return (
    <Box marginLeft={"2%"} marginRight={"2%"} marginTop={"0.5%"}>
      <NavHeader first={data.name} second="Verein" third="Vereinsdaten"/>
      <Typography fontWeight={700} variant="h5"component="h5" marginBottom={"1%"} marginTop={"1%"} marginLeft={"1%"} > Vereinsdaten </Typography>
      <Paper>
        <Box marginLeft={"1%"} marginTop={"3%"} marginRight={"1%"}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
            <Paper>
              <Typography fontWeight={700}>Name:</Typography>
          <Box display={"flex"} justifyContent={"space-between"}> <Typography>{data.name}</Typography></Box>
        </Paper>
            </Grid>
            <Grid item xs={4}>
            <Paper>
              <Typography fontWeight={700}>Adresse:</Typography>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography>{data.adress.street}</Typography>
            </Box>
        </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                <Typography fontWeight={700}>VKZ/ID</Typography>
                <Typography>{data.vkz}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box marginLeft={"1%"} marginTop={"3%"} marginRight={"1%"}>
          <Typography fontWeight={700} variant="h6" component="h6">
            {" "}
            übergeordnete Hirachie
          </Typography>
          <br></br>
          <Paper>
            <ClubTable></ClubTable>
          </Paper>
        </Box>
        <br></br>
      </Paper>
    </Box>
  );
}

const ClubTable = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "VKZ",
      headerName: "VKZ",
      width: 110,
    },
  ];

  const rows = [
    { id: 1, name: "DSB", VKZ: 31421 },
    { id: 2, name: "Bayrischer Landesverband e.V", VKZ: 12345 },
  ];

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7]}
        checkboxSelection
      />
    </Box>
  );
};
