import {Box, Typography, Paper} from "@mui/material"
import { useForm } from "react-hook-form"
import axios from "axios"
import dayjs from "dayjs"
import { useState } from "react"
import { NavHeader } from "../components/utils/navHeader";

export default function Setting({accessToken}){

    const IP = "https://gknrod8tfd.execute-api.eu-central-1.amazonaws.com/dev"

    const {register, control, handleSubmit} = useForm();
    const [found,setFound] = useState([]);

    const onSubmit = async (data) => {
        if(data.birthdate != "" && data.birthdate != null )
            {data.birthdate = dayjs(data.birthdate,"YYYY-MM-DD").format("DD.MM.YYYY")}
        console.log(data)
        var res = await axios.post(`${IP}/search/get_user_by_name_and_birthdate`, data, {
            headers: {
                'Authorization': accessToken,
            }})
        console.log(res)
        setFound(res.data)
        console.log(found)
    }

    return(
        <Box marginLeft={"2%"} marginRight={"2%"} marginTop={"0.5%"} display="inline-block">
      <NavHeader first="FC-Bayern" second="Mitglieder" third="Mitgliedertabelle"/>
      <Typography fontWeight={700} variant="h5"component="h5" marginBottom={"1%"} marginTop={"1%"} marginLeft={"1%"}>Mitgliederverwaltung</Typography>
      <Box marginTop={"6%"}>
        <Paper>
        <br/>
          <Box marginLeft={"5%"}marginRight={"1%"}>
            <Box>        
            Zuerst muss gesucht werden, ob die Person bereits im System existiert! <br></br>
            Anhand von Personendaten suchen: <br/>
            <p>
            <form onSubmit={handleSubmit(onSubmit)}>
            Vorname=<input required type="text" {...register("firstname")}/><br/>
            Nachname=<input required type="text" {...register("lastname")}/><br/>
            Geburtsdatum=<input required type="date" {...register("birthdate")}/><br/>
            <button type="submit">Submit</button>
            </form>
            </p>
            <p>
            <form onSubmit={handleSubmit(onSubmit)}>
            Direkt über ID suchen: <br/>
            ID=<input required type="text" {...register("uuid")}/><br></br>
            <button type="submit">Submit</button>
            </form>
            </p>

            <Box>
                {found.length != 0 ? <>Ergebnisse:<br/>{found.map((entry) => {return (<Paper>{entry.firstname},{entry.lastname},{entry.adress.city}</Paper>)})}</> : <div></div>}

            </Box>
            </Box>
            </Box>
            <br></br>
            </Paper>
        </Box>
        </Box>


    )
}