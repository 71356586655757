import axios from "axios"
import { fetchAuthSession } from 'aws-amplify/auth';
import { useState } from "react";

const IP = "https://gknrod8tfd.execute-api.eu-central-1.amazonaws.com/dev"


export const getPlayersByClub = async (club_id,accessToken) => {
  console.log(accessToken)
  const headers = {
    headers: {
        'Authorization': accessToken,
    }}

  try{
    console.log("getting Playerdata!")
    if(club_id==null){throw new Error ("Kein Club_id")}
    return axios.get(`${IP}/clubs/${club_id}` ,headers)
  } catch (error){
    console.log(error)
    alert(error)
  }
}

export const getClubData = async (club_id,accessToken) => {

  try{
    console.log("getting Playerdata!")
    console.log(club_id)
    if(club_id==null){throw new Error ("Keine Club_id")}
    return axios.get(`${IP}/club/${club_id}`, {
      headers: {
          'Authorization': accessToken,
      }})
  } catch (error){
    console.log(error)
    alert(error)
  }
}

export async function updatePlayers(club_id,playerSetter,accessToken){
  try{
  axios.get(IP + "/clubs/" + club_id,{
    headers: {
        'Authorization': accessToken,
    }}).then((result) => (playerSetter(result.data)));
}catch(error){
  console.log(error)
  alert(error)
}
}

export async function postNewUser(club_id, data, accessToken){

  const headers = {
    headers: {
        'Authorization': accessToken,
    }}

  try{
    console.log(accessToken)
    await axios.post(IP + "/clubs/" + club_id,JSON.stringify(data),headers)}
  catch(error){
    console.log(error)
    alert(error)
  }
}

export async function putUpdatedUser(club_id,data,accessToken){
  try{
  console.log("Sending Updated Player Infos to Server !")
  console.log(club_id)
  return await axios.put(IP + "/users" + "?club_id=" + club_id,data,{
    headers: {
        'Authorization': accessToken,
    }});
  } catch(error){
    console.log(error)
    alert(error)
  }
}


export async function putUpdatedClub(club_id,data,accessToken){
  try{
  console.log("Sending Updated Player Infos to Server !")
  console.log(club_id)
  return await axios.patch(IP + "/club" ,data,{
    headers: {
        'Authorization': accessToken,
    }});
  } catch(error){
    console.log(error)
    alert(error)
  }
}

export async function deleteUser(club_id,player_id,membership,accessToken){
  try{
    await axios.delete(IP + "/clubs/" + club_id + "?player_id=" + player_id + "&" + "membership=" + membership,{
      headers: {
          'Authorization': accessToken,
      }})
  } catch(error){
    console.log(error)
    alert(error)
  }
}

export async function deleteOfficial(club_id,official_id,accessToken){
  try{
    await axios.delete(IP + "/officals/" + club_id + "?official_id=" + official_id + "&username=" + accessToken.payload.username,{
      headers: {
          'Authorization': accessToken,
      }})
  } catch(error){
    console.log(error)
    alert(error)
  }
}


export async function postNewOffical(club_id, data,accessToken){
  try{
    data.username=accessToken.payload.username;
    await axios.post(IP + "/officals/" + club_id,JSON.stringify(data),{
      headers: {
          'Authorization': accessToken,
      }});}
  catch(error){
    console.log(error)
    alert(error)
  }
}

export default function (){
  return
}

/*
async function currentSession() {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    console.log( { accessToken, idToken })
    return  Promise.resolve(idToken.payload.profile)
}
    */
