import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import AddUser from '../../pages/addUser';
import { Controller,useForm } from 'react-hook-form';
import { Typography, Paper, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en-gb";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import { currentSession, postNewUser, putUpdatedUser} from "../backend";

export function EditOfficalsDialog(){
    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = async () => {
      setOpen(false)
    };
  
    return (
      <React.Fragment>
        <Button variant="contained" sx={{color:"black",backgroundColor:"white"}} onClick={handleClickOpen}>
          <EditIcon></EditIcon>
        </Button>
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} sx={{zIndex: 10001}} >
          <DialogTitle><Typography fontWeight={700} >Benutzer bearbeiten</Typography></DialogTitle>
          <DialogContent>
            
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  

    
}