import React from 'react';
import ReactDOM from 'react-dom/client';
import {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { IconButton,Button,} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ContrastIcon from '@mui/icons-material/Contrast';

export function TopMenu(props) {
  var color = "#2196f3"
  var fontColor = "black"
    //props.darkmode ? color="#424242" : color="#2196f3"
    if(props.darkMode){color = '#121212';fontColor = "white"}else{color = 'white';fontColor="black"}
      return (
        <Box >
          <AppBar position="static">
            <Toolbar  sx={{backgroundColor:color}}>
                <Typography variant="h6" component="div" sx={{ color:fontColor}}>{props.user.username}</Typography>
                <Typography variant="h6" sx={{color:fontColor, marginLeft:"1%"}}>VKZ: </Typography>
                <Typography variant="h6" sx={{flexGrow: 1 ,color:fontColor,}}> <Button onClick={() => {var Check = prompt('Zu welchem Verein wollen sie welchsen?', '');if(Check){props.changeClub_id(Check)}}}> {props.club_id} </Button></Typography>
                <Box>
                <IconButton icon="" onClick={props.themeChange()}><ContrastIcon></ContrastIcon></IconButton>
                <Button sx={{color:fontColor}} variant='outlined' onClick={props.signOut}>Logout</Button>
                </Box>
            </Toolbar>
          </AppBar>
        </Box>
      );
    }
    