import { Container, Divider, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { NavHeader } from "../components/utils/navHeader";

export default function Communication() {
  return (
    <Box marginLeft={"2%"} marginRight={"2%"} marginTop={"0.5%"}>
      <NavHeader first="FC-Bayern" second="Beträge" third="Beitragsdaten"/>
      <Typography fontWeight={700} variant="h5"component="h5" marginBottom={"1%"} marginTop={"1%"} marginLeft={"1%"} > Kommunikation </Typography>
      <Box marginLeft={"1%"} marginTop={"1%"} sx={{maxWidth:"700px"}}>
      <Paper marginRight={"2%"}>
        <Box marginLeft={"2%"} >
      <Typography variant="h6" > Hier können emails an ihre Mitglieder verschicken!</Typography>
      </Box>
      </Paper>
      <br></br>
      <Paper>
      <br></br>
        <Box marginRight={"2%"} marginLeft={"2%"}>

        <Typography>Empfänger:</Typography>
        <div sx={{width:"400px"}}><input name="reciever" required></input></div>
        <Typography>Betreff:</Typography>
        <div sx={{width:"400px"}}><input name="reciever" required></input></div>
        <Typography>Inhalt:</Typography>
        <textarea id="w3review" name="w3review" rows="4" cols="50"></textarea>
        <br></br>
        <input type="submit"></input>
        </Box>
        <br></br>
      </Paper>

    </Box>
    </Box>
  );
}
