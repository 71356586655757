import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export function ExtraEmails({emails,register}){
        
    switch(emails){
        case(2):
            return(
            <Box marginTop={"1%"}>
                <TextField variant="outlined" label="Email-Adresse" type="email" {...register("emails.email2")}></TextField>
            </Box>
            )
        case(3):
            return(
            <Box marginTop={"1%"}>
                <TextField variant="outlined" label="Email-Adresse" type="email" {...register("emails.email2")}></TextField>
                <TextField variant="outlined" label="Email-Adresse" type="email" {...register("emails.email3")}></TextField>
            </Box>)
        case(4):
        return(
        <Box marginTop={"1%"}>
            <TextField variant="outlined" label="Email-Adresse" type="email" {...register("emails.email2")}></TextField>
            <TextField variant="outlined" label="Email-Adresse" type="email" {...register("emails.email3")}></TextField>
            <TextField variant="outlined" label="Email-Adresse" type="email" {...register("emails.email4")}></TextField>
        </Box>)
    }
}