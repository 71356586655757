import { Box, Paper, Grid, Typography, CircularProgress } from "@mui/material"
import { NavHeader } from "../components/utils/navHeader";
import { DeleteClubDialog } from "../components/administration/deleteClubDialog";
import { DeleteAllMembersDialog } from "../components/administration/deleteAllMembers";
import { ChangeVKZDialog } from "../components/administration/changeVKZDialog";
import { useState,useEffect } from 'react';
import { getClubData } from '../components/backend';
import { CreateClubDialog } from "../components/administration/createClubDialog";

export default function Administration({club_id, accessToken}){

  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(()=> {
      const fetchData = async () => {
        setLoading(true);
        setError(null);
        if (club_id !="" && club_id != null)
        {
          try{
        console.log(club_id)
        const data = await getClubData(club_id,accessToken);
        console.log("Server Response:")
        console.log(data)
        setData(data.data)
      } catch (error){
        setError(error)
        console.log(error)
      } finally{
        setLoading(false);
      }
      }
        else
        {console.log("NO CLUB")}
      }
      fetchData();
    },[club_id])


  if(loading){return <CircularProgress></CircularProgress>}


  return (
    <Box marginLeft={"2%"} marginRight={"2%"} marginTop={"0.5%"}>
            <NavHeader first={"FC-Bayern"} second={"Verein"} third = {"Administration"}></NavHeader>
            <Typography fontWeight={700} variant="h5"component="h5" marginBottom={"1%"} marginTop={"1%"} marginLeft={"1%"}>Administration</Typography>
    <Box sx={{ flexGrow: 1, marginTop: "2%", marginLeft:"1%" }}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
         <CreateClubDialog/>     
        </Grid>
        <Grid item xs={5}>
          <ChangeVKZDialog vkz={data.vkz} club_id={club_id} accessToken={accessToken}/>
        </Grid>
        <Grid item xs={5}>
          <DeleteClubDialog/>    
        </Grid>
      </Grid>
    </Box>
    </Box>
  );




}

/**
 *         <Grid item xs={5}>
          <DeleteAllMembersDialog/>    
        </Grid>
 * 
 */