import React from "react";
import ReactDOM from "react-dom/client";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Typography, CircularProgress } from "@mui/material";
import { getClubData } from "./components/backend";
import { useEffect, useState,useCallback  } from "react";
import { get } from 'aws-amplify/api';

export default function Dashboard({club_id,accessToken}) {
  const [data, setData] = useState(); //Speicherung der Vereinsdaten


  useEffect(()=> { //UseEffekt holt einmal bei erstellen der Komponente und bei Änderung der Abhängikeiten
    async function getClubData() {
      try {
        if(club_id == null || club_id == ""){return} //Falls die VereinsID noch nicht bereit ist, nichts unternehmen
        const restOperation = get({    //API CALL AN Lambda
          apiName: 'smileAmplifyApi',
          path: `/club/${club_id}`,
          headers: {
            'Authorization': accessToken  //JWT in den Header für Cognito
          }
        });
        const {body} = await restOperation.response;
        const json = await body.json(); //Umwandlung der Antwort in JSON
        setData(json)
        console.log('GET call succeeded: ', json);
        return json
      } catch (e) {
        console.log('GET call failed: ', JSON.parse(e.response.body)); //Bei Fehler error Zurückgeben
      }
    }
      getClubData();
  },[club_id]) //Bei Änderung des Vereins muss neu Abgefragt werden

  var today = new Date() //Aktuelles Datum für die Mitgliederzählung
  var monthYear = `${today.getMonth()+1}${today.getFullYear()}` //getMonth startet, warum auch immer bei 0, deshalb muss 1 addiert werden.
  console.log(`Aktueller Monat und aktuelles Jahr: ${monthYear}`)

  return (
    <Box >
      <Typography variant="h4" sx={{margin:"2%"}} > Mein Portal </Typography>
      <Box sx={{ flexGrow: 1,marginLeft:"1%",marginRight:"1%"}}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Paper sx={{height: "300px"}}><Box justifyContent={"center"} display={"flex"}><Typography fontSize={"2.5em"}> Mitglieder </Typography></Box> <Box justifyContent={"center"} display={"flex"}><Typography fontSize={"2.5em"}> {data == null? <CircularProgress/> : data.count.count} </Typography></Box></Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper sx={{height: "300px"}}> 
            <Box justifyContent={"center"} display={"flex"}><Typography fontSize={"2.5em"}> Neue Mitglieder diesen Monat </Typography> </Box>
            <Box justifyContent={"center"} display={"flex"}><Typography fontSize={"2.5em"}> {data == null? <CircularProgress/> : data.count[`usersDate${monthYear}`]} </Typography></Box> </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper sx={{height: "300px"}}><Box justifyContent={"center"} display={"flex"}><Typography fontSize={"2.5em"}> Vereinsname </Typography></Box> 
            <Box justifyContent={"center"} display={"flex"}><Typography fontSize={"2.5em"}> {data == null? <CircularProgress/> : data.name} </Typography></Box>
            </Paper>
          </Grid>
          <Grid item xs={4}>
          <Paper sx={{height: "300px"}}>
            <Box justifyContent={"center"} display={"flex"}><Typography fontSize={"2.5em"}> Ihr Account </Typography></Box>
            <Box justifyContent={"center"} display={"flex"}> <Typography fontSize={"2em"}> Username = {accessToken ? accessToken.payload.username : "loading"} </Typography> </Box>
            <Box justifyContent={"center"} display={"flex"}> <Typography fontSize={"2em"}> Cognito Gruppe = {accessToken ? accessToken.payload["cognito:groups"] : "loading"} </Typography> </Box>
          </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper sx={{height: "300px"}}><Box justifyContent={"center"} display={"flex"}><Typography fontSize={"2.5em"}> Letzte Änderungen </Typography></Box> </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper sx={{height: "300px"}}><Box justifyContent={"center"} display={"flex"}><Typography fontSize={"2.5em"}> Events </Typography></Box></Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
