import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import AddUser from '../pages/addUser';
import { Controller,useForm } from 'react-hook-form';
import { Typography, Paper, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en-gb";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import { currentSession, postNewUser, putUpdatedUser} from "./backend";
import zIndex from '@mui/material/styles/zIndex';
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015
import { put } from 'aws-amplify/api';

export default function EditDialog(props) {
  const [open, setOpen] = React.useState(false);
  dayjs.extend(customParseFormat);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    await props.updatePlayers()
    setOpen(false)
  };

  return (
    <React.Fragment>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon></EditIcon>
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} sx={{zIndex: 1055}} >
        <DialogTitle><Typography fontWeight={700} >Benutzer bearbeiten</Typography></DialogTitle>
        <DialogContent>
            <EditMenu sx={{zIndex: 10058}} accessToken={props.accessToken} club_id={props.club_id} player={props.player} handleClose={handleClose} updatePlayers={props.updatePlayers}></EditMenu>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );




function EditMenu(props){
    const options = ["Mann", "Frau"];
    const form = useForm()
    const { control, register, handleSubmit} = form
    console.log("Player=")
    console.log(props.player)

    const onSubmit = async (data) =>{
        
        if(data.birthdate == null){
            data.birthdate = props.player.birthdate
        } else{
            data.birthdate = data.birthdate.format("DD.MM.YYYY")
        }
        if(data.gender == ""){
            data.gender = props.player.gender
        }
       
        if(data.active_since == "" || data.active_since == null){
          data.active_since = props.player.active_since
        } else {
          data.active_since = data.active_since.format("DD.MM.YYYY")
        }
        data.creation_date = props.player.creation_date
        data.oldPlayer = props.player
        data.id = props.player.id
        console.log("Ready Data=");
        console.log(data);
        try {
          const restOperation = put({    //API CALL AN Lambda
            apiName: 'smileAmplifyApi',
            path: `/member/${props.club_id}?memberId=${props.player.id}`,
            headers: {
              'Authorization': props.accessToken  //JWT in den Header für Cognito
            },
            options: {
              body: data,
            }
          });
          const {body} = await restOperation.response;
          const json = await body.json(); //Umwandlung der Antwort in JSON
          console.log('PUT call succeeded: ', json);
        } catch (e) {
          console.log('PUT call failed: ', e); //Bei Fehler error Zurückgeben
        }
        await handleClose();
      }


    return (
        <Box
      component="form"
      sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      
        <Box>
          <Box>
              <Typography> Allgemeine Daten </Typography>
              <Box marginTop="0.5%">
            <TextField required id="outlined-required" label="Name" defaultValue={props.player.lastname} {...register("lastname")} />
            <TextField required id="outlined-required" label="Vorname" defaultValue={props.player.firstname} {...register("firstname")} />
            <Controller required control={control} name="birthdate" render={({ field }) => ( <DatePicker required defaultValue={dayjs(props.player.birthdate, "DD-MM-YYYY" )} label="Geburtstag" placeholderText="Select date" onChange={(date) => field.onChange(date)} selected={field.value} /> )} />

            <div style={{ display: "inline-flex" }}>
              <Autocomplete disablePortal defaultValue={props.player.gender} required id="gender" options={options} sx={{ width: 300 }} renderInput={(params) => ( <TextField {...params} label="Geschlecht"  defaultValue={props.player.gender} required {...register("gender")}/> )} />
            </div>
            </Box>
          </Box>
          <Box> 
            <Typography> Kontaktdaten </Typography>
            <Box marginTop="0.5%">
            <TextField required id="outlined-required" label="Email" defaultValue={props.player.email} type="email" {...register("email")} />
            <TextField required id="outlined-required" defaultValue={props.player.phone} label="Telefonnummer" type="tel" {...register("phone")} />
            </Box>
            <Typography> Adressdaten </Typography>
            <Box display="flex">
              <TextField required id="outlined-required" label="Land" defaultValue={props.player.adress.country} {...register("adress.country")}/>
              <TextField required id="outlined-required" label="Bundesland" defaultValue={props.player.adress.state} {...register("adress.state")}/>
              <TextField required id="outlined-required" label="Postleitzahl" defaultValue={props.player.adress.postalcode} {...register("adress.postalcode")}/>              
              <TextField required id="outlined-required" label="Stadt" defaultValue={props.player.adress.city} {...register("adress.city")}/>   
            </Box>
            <Box display="flex">   
              <TextField required id="outlined-required" label="Straße" defaultValue={props.player.adress.street} {...register("adress.street")}/>
              <TextField required id="outlined-required" label="Hausnummer" defaultValue={props.player.adress.house_number} {...register("adress.house_number")}/>
            </Box>  
          </Box>
          <Box >
            <Typography> Spielereigenschaften </Typography>
            <Box marginTop="0.5%">
              <TextField required id="outlined-required" select label="Mitgliedschaft" defaultValue={props.player.membership} {...register("membership")}> 
                <MenuItem value="active">aktiv</MenuItem>
                <MenuItem value="passive">passiv</MenuItem></TextField>
              <TextField id="outlined-required" label="FideID" defaultValue={props.player.fide_id} {...register("fide_id")} />
              <TextField id="outlined-required" label="Gastspielberechtigung" defaultValue={props.player.guestplay} {...register("guestplay")}/>
              <Controller
                control={control} name="active_since"render={({ field }) => (
                  <DatePicker defaultValue={dayjs(props.player.active_since, "DD-MM-YYYY" )} label="Aktiv seit" placeholderText="Select date" onChange={(date) => field.onChange(date)} selected={field.value}/>
                )}
              />
            </Box>
          </Box>
        </Box>
        <br></br>
        <Box justifyContent={"left"} display={"flex"}>
        <DialogActions>
          <Button variant="contained" color="error" onClick={props.handleClose}>Abbrechen</Button>
          <Button variant="contained" type="submit" color="success">Speichern</Button>
        </DialogActions>
        </Box>
      </LocalizationProvider>
    </Box>
    )
}
}