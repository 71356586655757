import { get } from 'aws-amplify/api';

export default function Testing(){
      const res = getClubData()
      console.log(res)

return (<>hi</>)
}

async function getClubData() {
    try {
      const restOperation = get({ 
        apiName: 'smileAmplifyApi',
        path: '/club/1' 
      });
      const {body} = await restOperation.response;
      const json = await body.json();
      console.log('GET call succeeded: ', json);
      return json
    } catch (e) {
      console.log('GET call failed: ', JSON.parse(e.response.body));
    }
  }